<template>
<v-container fluid fill-height v-if="show">
    <v-card :class="`align-self-stretch ma-2 ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Баланс</h1>
        </v-card-title>
        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="!Object.keys(data).length && loaded ">
            Немає жодного доступного контрагента для перегляду взаєморозрахунків.
        </v-alert>
        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="contactInfo.userId ? (contactInfo.isConfirmed ? false : true) : false">
            Немає доступу до інформації. Ваші контактні дані не підтверджені менеджером.
        </v-alert>

        <AlertCompanyBlock />

        <v-list-group v-if="contactInfo.userId && contactInfo.isConfirmed && contactInfo.groups && contactInfo.groups.cladmin_accounts ? true :false" :value="true" no-action sub-group v-for="({calcDate, dogs}, contractor) in data" :key="contractor">

            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>{{contractor}}</v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list-item v-if="contactInfo.groups && contactInfo.groups.cladmin_finconfirm ? true : false" class="px-0 pl-1 pt-1 mb-5">
                <v-card class="mb-5" :width="$vuetify.breakpoint.mdAndUp ? `30%` : `99%`" :min-width="$vuetify.breakpoint.smAndUp ? `450` : ``" elevation="4">
                    <v-card-text class="pb-0">
                        <v-alert outlined type="error" v-if="expire(calcDate)">
                            Термін звірки взаєморозрахунків по Контрагенту протерміновано!<br>
                            Необхідно провести та підтвердити звірку!
                        </v-alert>
                        <h4>Провести звірку за період:</h4>
                        <div v-if="exceptCalc(calcDate) == 0">
                            <v-row>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="subtitle-1 pb-2">з
                                        <v-icon class="mr-1 ml-3">event</v-icon>{{formatDate(calcDate.calc_min_date)}}
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-menu v-model="menu[calcDate.name]" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field @click="createError[calcDate.name] = false" :error-messages="createError[calcDate.name] ? 'Заповніть дату':''" :error="createError[calcDate.name] ? true : false" v-model="periodToFormatted[calcDate.name]" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker first-day-of-week="1" locale-first-day-of-year="1" :min="calcDate.calc_min_date" :max="calcDate.calc_max_date" color="primary" header-color="primary" locale="uk-ua" v-model="periodTo[calcDate.name]" @input="menu[calcDate.name] = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </div>
                        <v-alert outlined type="success" color="link" class="my-4" v-if="exceptCalc(calcDate) == 1 ">Звірка буде доступна після {{formatDate(calcDate.curr_month)}}.</v-alert>
                        <v-alert outlined type="warning" class="my-4" v-if="exceptCalc(calcDate) == 2 ">Звірка буде доступна після {{formatDate(calcDate.next_month)}}.</v-alert>
                        <v-alert outlined type="success" class="my-4" v-if="exceptCalc(calcDate) == 3 ">Термін звірки ще не настав.</v-alert>

                    </v-card-text>
                    <v-card-actions v-if="exceptCalc(calcDate) == 0" class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="create(calcDate, true)">до підтвердження</v-btn>
                    </v-card-actions>
                </v-card>
            </v-list-item>

            <v-list-item class="px-0">
                <v-list-item-content class="mb-5" width="100%">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-simple-table dense fill-height>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left px-1 px-sm-4" width="70%">
                                                Договір
                                            </th>
                                            <th class="text-center px-1 px-sm-4" width="30%">
                                                Остання звірка
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, idx) in dogs" :key="item.dog_number">
                                            <td class="text-left px-1 px-sm-4">{{ item.dog_number }}</td>
                                            <td :class="`text-center px-1 px-sm-4 ` + (expire(calcDate) ? `red--text font-weight-bold` : ``)">{{ formatDate(item.validation_date) }}
                                                <v-icon class="red--text mt-n1" v-if="expire(calcDate)" title="Термін звірки взаєморозрахунків по договору пройшов!">mdi-alert-circle</v-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="12">
                                    <h4>Оберіть період перегляду взаєморозрахунків:</h4>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-menu v-model="menu2[contractor]" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field @click="checkFromError[contractor] = false" :error-messages="checkFromError[contractor] ? 'Заповніть дату':''" :error="checkFromError[contractor] ? true : false" v-model="dateFromFormatted[contractor]" label="з" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker first-day-of-week="1" color="primary" header-color="primary" :max="currDate()" locale="uk-ua" v-model="dateFrom[contractor]" @input="menu2[contractor] = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-menu v-model="menu3[contractor]" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field @click="checkToError[contractor] = false" :error-messages="checkToError[contractor] ? 'Заповніть дату':''" :error="checkToError[contractor] ? true : false" v-model="dateToFormatted[contractor]" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker first-day-of-week="1" :min="dateFrom[contractor]" :max="maxDateTo[contractor] || currDate()" color="primary" header-color="primary" locale="uk-ua" v-model="dateTo[contractor]" @input="menu3[contractor] = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn :disabled="dateToTxt[contractor] && dateFromTxt[contractor] ? false : true" @click="check(dogs[0])">Показати</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

    </v-card>

    <v-dialog v-model="dialog" max-width="600">
        <v-card>
            <v-card-title class="headline">
                Звірка взаєморозрахунків
            </v-card-title>

            <div class="text-center pa-10" v-if="loading">
                <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
            </div>

            <v-card-text v-if="!loading">
                <div class="py-5">Виконаний перехід до форми Акту звірки взаєморозрахунків.</div>
                <v-alert type="warning" border="top" colored-border elevation="3"><b>Увага!</b> Якщо не відкрилося нове вікно браузера, то перевірте налаштування блокування вспливаючих вікон у браузері.</v-alert>
                <div>Дані по взаєморозрахункам сформовано у новому вікні. Якщо вікно не відкрилося, то перейдіть за посиланням: <a :href="url" target="_blank">Акт звірки взаєморозрахунків</a></div>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text v-if="!loading" @click="dialog = false">
                    Ок
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</v-container>
</template>

<script>
import {
    mapActions
} from 'vuex'
import AlertCompanyBlock from "./AlertCompanyBlock.vue"

export default {
    components: {
        AlertCompanyBlock
    },
    data: vm => ({
        menu: {},
        menu2: {},
        menu3: {},
        periodTo: {},
        periodToTxt: {},
        dateTo: {},
        dateFrom: {},
        dateFromTxt: {},
        dateToTxt: {},
        createError: {},
        checkFromError: {},
        checkToError: {},
        maxDateTo: {},
        loading: false,
        dialog: false,
        url: '',
        loaded: false,
        show: false
    }),
    watch: {
        '$route.params'() {
            this.$vuetify.goTo(0)
            this.successOrder()
        },
        '$route.path'() {
            this.$vuetify.goTo(0)
        },
        periodTo(val) {
            for (let c in val) {
                this.periodToFormatted[c] = this.formatDate(val[c])
            }
        },
        dateFrom: {
            handler(v) {
                for (let c in v) {
                    this.dateToTxt[c] = ''
                    this.maxDateTo[c] = this.calculateMax(v[c])
                }
            },
            deep: true,
        },
        dateTo: {
            handler(v) {
                for (let c in v) {
                    for (let c in v) {
                        this.dateToTxt[c] = this.formatDate(v[c])
                    }
                }
            },
            deep: true,
        },
        dialog(v) {
            if (!v) {
                this.Reconciliation()
            }
        }
    },

    methods: {
        ...mapActions(['Reconciliation', 'createReportToken', 'getContactInfo']),
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('-')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        currDate() {
            return new Date().toISOString().slice(0, 10)
        },
        exceptCalc(item) {
            let except = null
            if (item.t_diff > 0) {
                if (item.fd_date) {
                    except = 1
                } else {
                    if (item.ed_date) {
                        except = 2
                    } else {
                        except = 0
                    }
                }
            } else {
                except = 3
            }
            return except
        },
        expire(item) {
            for (let contractor in this.dogs) {
                if (item.name == contractor) {
                    return this.dogs[contractor].find(el => el.exp_date == 1) ? true : false
                }
            }
        },
        create(item, reconciliation = false) {
            this.createError = {}
            if (!this.periodTo[item.name]) {
                this.$set(this.createError, item.name, true)
                return
            }
            const f = {
                periodFrom: item.calc_min_date,
                periodTo: this.periodTo[item.name],
                contractorId: item.ca_id,
                reconciliation

            }
            this.dialog = true
            this.loading = true
            this.createReportToken(f)
                .then(data => {
                    this.loading = false
                    this.openFininfo(data)
                })
                .catch(error => console.log(error))
        },
        check(item, reconciliation = false) {
            this.checkToError = {}
            this.checkFromError = {}
            if (!this.dateTo[item.name]) {
                this.$set(this.checkToError, item.name, true)
            }
            if (!this.dateFrom[item.name]) {
                this.$set(this.checkFromError, item.name, true)
            }
            if (new Date(this.dateFrom[item.name]) > new Date(this.dateTo[item.name])) {
                alert('Період з не може бути менье періоду по')
                return
            }
            if (!this.dateTo[item.name] || !this.dateFrom[item.name]) {
                return
            }
            const f = {
                periodFrom: this.dateFrom[item.name],
                periodTo: this.dateTo[item.name],
                contractorId: item.ca_id,
                reconciliation
            }
            this.loading = true
            this.dialog = true
            this.createReportToken(f)
                .then(data => {
                    this.loading = false
                    this.openFininfo(data)
                })
                .catch(error => console.log(error))

        },
        calculateMax(date) {
            const d = new Date(date)
            const now = new Date()
            if (d > now) return this.currDate()
            const month = d.getMonth()
            d.setMonth(d.getMonth() + 6)
            while (d.getMonth() === month) {
                d.setDate(d.getDate() - 1)
            }
            if (d > now) return this.currDate()
            return d.toISOString().split('T')[0];
        },
        openFininfo(data) {
            this.url = process.env.NODE_ENV === 'production' ? `https://b2b.fininfo.com.ua?t=${data.token}` : `http://dev.yugcontract.ua:3070?t=${data.token}`
            window.open(this.url)
        },
        checkDirect() {
            return this.$route.query && Object.keys(this.$route.query) && Object.keys(this.$route.query)[0] === 'direct'
        }
    },
    computed: {
        calcDate() {
            return this.$store.state.balance.calcDate
        },
        dogs() {
            return this.$store.state.balance.dogs
        },
        data() {
            return this.$store.state.balance.data
        },
        computedperiodToFormatted() {
            return this.formatDate(this.periodTo)
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        dateFromFormatted() {
            for (let c in this.dogs) {
                this.dateFromTxt[c] = this.formatDate(this.dateFrom[c])
            }
            return this.dateFromTxt
        },
        dateToFormatted() {
            for (let c in this.dogs) {
                this.dateToTxt[c] = this.formatDate(this.dateTo[c])
            }
            return this.dateToTxt
        },
        periodToFormatted() {
            for (let c in this.dogs) {
                this.periodToTxt[c] = this.formatDate(this.periodTo[c])
            }
            return this.periodToTxt
        }
    },
    created() {
        this.getContactInfo()
            .then(data => {
                if (!data.isSellToEmployee && this.contactInfo.groups && this.contactInfo.groups.cladmin_accounts) {
                    this.show = true
                    if (this.checkDirect() && this.contactInfo.isConfirmed) {
                        this.createReportToken({
                                direct: true
                            })
                            .then(data => {
                                if (data.token) {
                                    this.url = (process.env.NODE_ENV === 'production' ? `https://b2b.fininfo.com.ua?t=${data.token}` : `http://dev.yugcontract.ua:3070?t=${data.token}`)
                                    this.dialog = true
                                    window.open(this.url)
                                }
                            })
                            .catch(error => console.log(error))
                    }
                    this.Reconciliation()
                        .then(() => {
                            this.loaded = true
                        })
                        .catch(error => console.log(error))
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))
    }
}
</script>

<style>
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
    padding-left: 0;
}
</style>
