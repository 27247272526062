<template>
    <BalancePage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import BalancePage from '../components/BalancePage.vue'

export default {
    data: () => ({
    }),
    components: {
        BalancePage
    },
    methods: {
        ...mapActions(['getUserInfo'])
    },
    mounted() {
        //this.getUserInfo(this)
    }
}
</script>

